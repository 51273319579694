body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eaeaea;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.app {
    min-height: calc(100vh - 80px);
    position: relative;
}
.content-wrap {
    padding-bottom: 4.5rem;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    background-color: #dadada;
    padding-top: 8px;
}

#rav-viener-picture {
    display: inline-block;
    float: right;
}
@media screen and (max-width: 768px) {
    #rav-viener-picture {
        display: none;
    }
    .footer {
        height: 4rem;
    }
}

.email::after {
    content: attr(data-email);
    color: darkblue;
}
